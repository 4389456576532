<template>
  <div class="view-root flex-column">
    <h3>日程嘉宾列表</h3>
    <div style="text-align: right">
      <el-button type="primary" size="small" @click="showGuestDialog = true"
        >添加嘉宾</el-button
      >
    </div>
    <el-table
      :data="dataList"
      class="flex-1-overflow-y"
      style="margin-top: 15px"
    >
      <el-table-column type="index" prop="index" label="序号"></el-table-column>
      <el-table-column prop="name" label="嘉宾姓名"></el-table-column>
      <el-table-column prop="role" label="嘉宾角色"></el-table-column>
      <el-table-column prop="" label="头衔">
        <template slot-scope="scope">
          <div style="display: flex; flex-wrap: wrap; gap: 5px">
            <el-tag
              v-for="(title, index) in scope.row.titles"
              :key="index"
              type="success"
              >{{ title }}</el-tag
            >
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="company" label="所在公司"></el-table-column>
      <el-table-column prop="" label="头像">
        <template slot-scope="scope">
          <img
            :src="scope.row.avatar_url"
            style="width: 72px; height: 72px; object-fit: cover"
          />
        </template>
      </el-table-column>
      <el-table-column prop="" label="操作">
        <template slot-scope="scope">
          <el-button
            type="danger"
            size="small"
            @click="removeGuestInfo(scope.row)"
            >删除</el-button
          >
          <el-button
            type="primary"
            size="small"
            @click="editGuestInfo(scope.row)"
            >编辑</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <el-dialog :visible.sync="showGuestDialog" title="选择嘉宾" width="75%">
      <el-button @click="addGuest" type="primary">新增嘉宾</el-button>
      <div style="max-height: 500px; overflow-y: auto; margin-top: 10px">
        <el-table :data="availableGuests" :border="true">
          <el-table-column
            type="index"
            prop="index"
            label="序号"
            width="100px"
          ></el-table-column>
          <el-table-column prop="name" label="嘉宾姓名"></el-table-column>
          <el-table-column prop="role" label="嘉宾角色"></el-table-column>
          <el-table-column prop="" label="头衔">
            <template slot-scope="scope">
              <div style="display: flex; flex-wrap: wrap; gap: 5px">
                <el-tag
                  v-for="(title, index) in scope.row.titles"
                  :key="index"
                  type="success"
                  >{{ title }}</el-tag
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="company" label="所在公司"></el-table-column>
          <el-table-column prop="" label="头像">
            <template slot-scope="scope">
              <img
                :src="scope.row.avatar_url"
                style="width: 72px; height: 72px; object-fit: cover"
              />
            </template>
          </el-table-column>
          <el-table-column prop="" label="操作">
            <template slot-scope="scope">
              <el-button
                type="primary"
                size="small"
                @click="editGuest(scope.row)"
                >编辑信息</el-button
              >
              <el-dropdown @command="confirmGuest(scope.row, $event)">
                <el-button
                  type="danger"
                  size="small"
                  >选择</el-button
                >
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="主持人">添加为主持人</el-dropdown-item>
                  <el-dropdown-item command="演讲人">添加为演讲人</el-dropdown-item>
                  <el-dropdown-item command="分享人">添加为分享人</el-dropdown-item>
                  <el-dropdown-item command="致辞人">添加为致辞人</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
              <el-popover
                placement="top-end"
                trigger="hover"
                content="文件仅支持 PDF 格式"
              >
              </el-popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-dialog>
    <el-dialog :visible.sync="showDialog" title="编辑嘉宾">
      <div>
        <el-form :model="guestInfo" :rules="rules" ref="guestInfo">
          <el-form-item label="嘉宾姓名" prop="name">
            <el-input v-model="guestInfo.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="头像">
            <el-upload
              class="avatar-uploader"
              action="/"
              :show-file-list="false"
              :before-upload="(file) => beforeAvatarUpload(file)"
            >
              <img
                v-if="guestInfo.avatar_url"
                :src="guestInfo.avatar_url"
                style="object-fit: cover"
                class="el-icon-plus avatar-uploader-icon"
              />
              <i v-else class="el-icon-plus avatar-uploader-icon"></i>
            </el-upload>
          </el-form-item>
          <el-form-item label="嘉宾角色" prop="role">
            <el-select v-model="curGuestRole" placeholder="请选择">
              <el-option label="主持人" value="主持人"></el-option>
              <el-option label="演讲人" value="演讲人"></el-option>
              <el-option label="分享人" value="分享人"></el-option>
              <el-option label="致辞人" value="致辞人"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="">
            <span>头衔</span>
            <vue-tags-input
              v-model="tag"
              :tags="tags"
              placeholder="请输入"
              @tags-changed="(newTags) => (tags = newTags)"
            />
          </el-form-item>
          <el-form-item label="所在公司" prop="">
            <el-select
              v-model="guestInfo.company"
              placeholder="请选择"
              style="width: 400px"
            >
              <el-option
                v-for="(companyName, index) in companyArr"
                :key="index"
                :label="companyName.partner.company"
                :value="companyName.partner.id"
              ></el-option>
            </el-select>
            <!-- <el-input
                    v-model="guestInfo.company"
                    placeholder="请输入"
                    ></el-input> -->
          </el-form-item>
          <el-form-item label="">
            <label>单位介绍</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.company_intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>

          <el-form-item label="">
            <label>简介</label>
            <quill-editor
              class="editor"
              style="margin-bottom: 20px"
              ref="myTextEditor"
              v-model="guestInfo.intro"
              :options="editorOption"
            >
            </quill-editor>
          </el-form-item>
          <el-form-item label="登录账号">
            <el-input v-model="guestInfo.email" placeholder="请输入"></el-input>
          </el-form-item>
          <!-- <el-form-item label="密码">
                    <el-input
                    v-model="guestInfo.password"
                    placeholder="请输入"
                    ></el-input>
                </el-form-item> -->
        </el-form>
        <div style="text-align: right">
          <span slot="footer" class="dialog-footer">
            <el-button @click="showDialog = false">取 消</el-button>
            <el-button type="primary" @click="saveGuestInfo">确 定</el-button>
          </span>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import VueTagsInput from "@johmun/vue-tags-input";
import {
  getbrandPartys,
  getGuests,
  getQiniuToken,
  getSessionDetail,
  updateGuest,
  updateSessionGuests,
  postRequest,
} from "../../../api/api";
import { getUserId } from "../../../utils/store.js";

import "../../../assets/common/common.css";
export default {
  components: {
    VueTagsInput,
  },
  name: "index",
  data() {
    return {
      session_id: this.$route.query.session_id,
      meeting_id: this.$route.query.meeting_id,
      dataList: [],
      allGuests: [],
      availableGuests: [],
      showGuestDialog: false,
      showDialog: false,
      sessionGuestInfo: {},
      curGuestRole: '',
      guestInfo: {
        id: "",
        name: "",
        company: "",
        intro: "",
        avatar_url: "",
        role: "",
        company_intro: "",
        email: "",
        password: "",
      },
      rules: {},
      editorOption: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], // 加粗 斜体 下划线 删除线
            ["blockquote", "code-block"], // 引用  代码块
            [{ header: 1 }, { header: 2 }], // 1、2 级标题
            [{ list: "ordered" }, { list: "bullet" }], // 有序、无序列表
            [{ script: "sub" }, { script: "super" }], // 上标/下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            // [{'direction': 'rtl'}],                         // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
            [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
            [{ font: [] }], // 字体种类
            [{ align: [] }], // 对齐方式
            ["clean"], // 清除文本格式
            ["link", "image", "video"], // 链接、图片、视频
          ], // 工具菜单栏配置
        },
        placeholder: "", // 提示
        readyOnly: false, // 是否只读
        theme: "snow", // 主题 snow/bubble
        syntax: true, // 语法检测
      },
      tag: "",
      tags: [],
      companyArr: [],
    };
  },
  mounted() {
    this.requestAllGuests();
    this.fetchData();
  },
  methods: {
    confirmGuest(row, roleKey) {
      let select_id = row._id.$id;
      let ids = [];
      let enable = true;
      for (let i = 0; i < this.dataList.length; i++) {
        let id = this.dataList[i]._id.$id;
        if (id == select_id) {
          enable = false;
          break;
        } else {
          ids.push(id);
        }
      }
      if (!enable) {
        this.$message.error("已经选择了该嘉宾");
        return;
      }
      ids.push(select_id);
      this.sessionGuestInfo[select_id] = {
        role: roleKey,
      }
      console.log(123, this.sessionGuestInfo)
      updateSessionGuests(this.session_id, ids, this.sessionGuestInfo).then((res) => {
        this.$message.success("添加成功");
        this.fetchData();
        // this.requestAllGuests()
        this.showGuestDialog = false;
      });
    },

    requestAllGuests() {
      postRequest({ function: "getGuests", organizer_id: getUserId() }).then(
        (res) => {
          this.allGuests = res.data.data;
          this.updateAvailableGuests();
        }
      );
    },
    updateAvailableGuests() {
      this.availableGuests = [];
      console.log("updateAvailableGuests", this.dataList, this.allGuests);
      this.allGuests.forEach((guest) => {
        const isInDataList = this.dataList.some(
          (dataGuest) => dataGuest._id.$id === guest._id.$id
        );
        if (!isInDataList) {
          this.availableGuests.push(guest);
        }
      });
    },

    fetchData() {
      getSessionDetail(this.session_id)
        .then((res) => {
          const guest_info = res.data.data.guest_info
          if (Object.prototype.toString.call(guest_info).slice(8, -1) !== "Object") {
            this.sessionGuestInfo = {}
          } else {
            this.sessionGuestInfo = res.data.data.guest_info
          }
          this.dataList = res.data.data.guests.map(item => {
            const role = this.sessionGuestInfo[item._id.$id]?.role || "";
            return {...item, role };
          });

        })
        .finally(() => {
          this.updateAvailableGuests();
        });
      postRequest({
        function: "getMeetingPartners",
        meeting_id: this.meeting_id,
      }).then((res) => {
        this.companyArr = res.data.data;
      });
    },
    ajaxUpdateSessionGuests () {
      let ids = [];
      for (let i = 0; i < this.dataList.length; i++) {
        let id = this.dataList[i]._id.$id;
        ids.push(id);
      }
      const guest_info = {}
      ids.forEach((id) => {
          guest_info[id] = this.sessionGuestInfo[id]
      })

      this.sessionGuestInfo = guest_info
      updateSessionGuests(this.session_id, ids, guest_info)
    },
    removeGuestInfo(row) {
      this.$alert("你确定要删除此嘉宾吗？", "提示", {
        confirmButtonText: "确定",
        callback: (action) => {
          if (action == "confirm") {
            let ids = [];
            for (let i = 0; i < this.dataList.length; i++) {
              let id = this.dataList[i]._id.$id;
              if (id != row._id.$id) {
                ids.push(id);
              }
            }
            const guest_info = {}
            ids.forEach((id) => {
                guest_info[id] = this.sessionGuestInfo[id]
            })

            this.sessionGuestInfo = guest_info
            updateSessionGuests(this.session_id, ids, guest_info).then((res) => {
              this.$message.success("删除成功");
              this.fetchData();
              // this.requestAllGuests()
            });
          }
        },
      });
    },
    /** 编辑嘉宾 */
    editGuestInfo(row) {
      this.editGuest(row);
    },

    async beforeAvatarUpload(file) {
      const result = await getQiniuToken();
      if (result.data.code === 0) {
        const token = result.data.data;
        this.observable = qiniu.upload(
          file,
          new Date().getTime() + file.name,
          token
        );
        const observer = {
          complete: (res) => {
            this.guestInfo.avatar_url =
              "http://mainbucket.reachable-edu.com/" + res.key;
            console.log("url:" + this.guestInfo.avatar_url);
          },
        };
        this.observable.subscribe(observer);
      }
    },
    saveGuestInfo() {
      var titles = [];
      for (var i = 0; i < this.tags.length; i++) {
        titles.push(this.tags[i].text);
      }
      if (titles.length <= 0) {
        this.$message.error("请填写头衔");
        return;
      }
      this.guestInfo.titles = titles;
      this.guestInfo.organizer_id = getUserId();
      console.log("Guest Info:", this.guestInfo);
      this.guestInfo.function = "updateGuest";
      this.sessionGuestInfo[this.guestInfo.id] = {
        role: this.curGuestRole,
      }
      postRequest(this.guestInfo).then((res) => {
        this.$message.success("保存成功");
        // this.requestAllGuests()
      }).finally(() => {
          this.ajaxUpdateSessionGuests()
          this.showDialog = false;
          setTimeout(async () => {
            this.fetchData();
          }, 500)
      })
    },
    addGuest() {
      this.showDialog = true;
      this.guestInfo = {
        id: "",
        name: "",
        company: "",
        intro: "",
        avatar_url: "",
        role: "",
        company_intro: "",
        email: "",
        password: "",
      };
      this.tag = "";
      this.tags = [];
    },
    editGuest(row) {
      this.showDialog = true;
      this.guestInfo = JSON.parse(JSON.stringify(row));
      this.guestInfo.id = row._id.$id;
      this.curGuestRole = row.role;
      this.tag = "";
      this.tags = [];
      for (var i = 0; i < row.titles.length; i++) {
        this.tags.push({ text: row.titles[i] });
      }
    },
  },
};
</script>
<style>
.ql-container {
  height: 160px !important;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
